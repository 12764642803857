import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeUrl } from '@angular/platform-browser';

@Pipe({ name: 'safe', standalone: true })
export class SafePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}
  transform(value: string, type: 'url' | 'style' | 'styleUrl' | 'html'): any {
    if (!value) return '';
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'styleUrl':
        return this.sanitizer.bypassSecurityTrustStyle("url('" + value + "')");
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      default:
        return '';
    }
  }
}
